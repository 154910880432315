// This file is part of sisdai-proyecto-base.
//
//   sisdai-proyecto-base is free software: you can redistribute it and/or modify
//   it under the terms of the GNU Lesser General Public License as published by the
//   Free Software Foundation, either version 3 of the License, or
//   (at your option) any later version.
//
//   sisdai-proyecto-base is distributed in the hope that it will be useful,
//   but WITHOUT ANY WARRANTY; without even the implied warranty of
//   MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU Lesser General
//   Public License for more details.
//
//   You should have received a copy of the GNU Lesser General Public License along
//   with sisdai-proyecto-base. If not, see <https://www.gnu.org/licenses/>.

import Vue from "vue";
import VueRouter from "vue-router";
import PaginaInicio from "../views/PaginaPresentacion.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/Inicio",
    name: "Inicio",
    component: PaginaInicio,
  },
  {
    path: "/amate",
    name: "amate",
    component: () => import("../views/AmateAmarillo.vue"),
  },
  {
    path: "/anil",
    name: "añil",
    component: () => import("../views/anilPlanta.vue"),
  },
  {
    path: "/barbasco",
    name: "barbasco",
    component: () => import("../views/BarbascoPlanta.vue"),
  },
  {
    path: "/cacalosuchil",
    name: "cacalosuchil",
    component: () => import("../views/CacalosuchilPlanta.vue"),
  },
  {
    path: "/calabaza",
    name: "calabaza",
    component: () => import("../views/CalabazaPlanta.vue"),
  },
  {
    path: "/cardon",
    name: "cardon",
    component: () => import("../views/CardonPlanta.vue"),
  },
  {
    path: "/copaoro",
    name: "CopaDeOro",
    component: () => import("../views/CopaOro.vue"),
  },
  {
    path: "/guie",
    name: "guie’ xhuba",
    component: () => import("../views/GuieXhuba.vue"),
  },
  {
    path: "/huaje",
    name: "huaje",
    component: () => import("../views/HuajePlanta.vue"),
  },
  {
    path: "/jicaro",
    name: "jicaro",
    component: () => import("../views/JicaroPlanta.vue"),
  },
  {
    path: "/linaloe",
    name: "linaloe",
    component: () => import("../views/LinaloePlanta.vue"),
  },
  {
    path: "/MaizCocostle",
    name: "MaizCocostle",
    component: () => import("../views/MaizCocostle.vue"),
  },
  {
    path: "/Mantecoso",
    name: "Mantecoso",
    component: () => import("../views/MantecosoPlanta.vue"),
  },
  {
    path: "/Nanachepa",
    name: "Nanachepa",
    component: () => import("../views/NanachepaPlanta.vue"),
  },
  {
    path: "/NopalSanGabriel",
    name: "NopalSanGabriel",
    component: () => import("../views/NopalSanGabriel.vue"),
  },
  {
    path: "/PalmaReal",
    name: "PalmaReal",
    component: () => import("../views/PalmaReal.vue"),
  },
  {
    path: "/PataDeGallo",
    name: "PataDeGallo",
    component: () => import("../views/PataDeGallo.vue"),
  },
  {
    path: "/RanitaSurena",
    name: "RanitaSurena",
    component: () => import("../views/RanitaSurena.vue"),
  },
  {
    path: "/RositaCacao",
    name: "RositaCacao",
    component: () => import("../views/RositaCacao.vue"),
  },
  {
    path: "/sabino",
    name: "OtraFicha",
    component: () => import("../views/SabinoPlanta.vue"),
  },
  {
    path: "/VenturillaPlanta",
    name: "VenturillaPlanta",
    component: () => import("../views/VenturillaPlanta.vue"),
  },
  {
    path: "/YerbaBorracho",
    name: "YerbaBorracho",
    component: () => import("../views/YerbaBorracho.vue"),
  },
  {
    path: "**",
    name: "pagina-no-encontrada",
    component: () => import("@/views/ErrorPaginaNoEncontrada.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0, behavior: "smooth" };
  },
});

// Muestra el título de la página dependiendo de las vistas
router.beforeEach((to, from, next) => {
  document.title = `${process.env.VUE_APP_TITLE} | ${to.name}`;
  next();
});

export default router;
