<!--This file is part of sisdai-proyecto-base.-->

<!--sisdai-proyecto-base is free software: you can redistribute it and/or modify-->
<!--it under the terms of the GNU Lesser General Public License as published by the-->
<!--Free Software Foundation, either version 3 of the License, or-->
<!--(at your option) any later version.-->

<!--sisdai-proyecto-base is distributed in the hope that it will be useful,-->
<!--but WITHOUT ANY WARRANTY; without even the implied warranty of-->
<!--MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU Lesser General-->
<!--Public License for more details.-->

<!--You should have received a copy of the GNU Lesser General Public License along-->
<!--with sisdai-proyecto-base. If not, see <https://www.gnu.org/licenses/>.-->

<script setup>
import { onBeforeUnmount, onMounted } from "vue";

function calHeight() {
  const innerheight = window.innerHeight / 100;
  document
    .querySelector(":root")
    .style.setProperty("--vh", `${innerheight.toString()}px`);
}

calHeight();

onMounted(() => {
  window.addEventListener("resize", calHeight);
});

onBeforeUnmount(() => {
  window.addEventListener("resize", calHeight);
});
</script>

<template>
  <main id="principal">
    <div class="contenedor-portada">
      <img
        class="portada centrado"
        src="../Recursos/CIRCULO_LOGO_devanagari_normal.jpg"
      />
      <div class="degradado-negro-abajo">
        <div class="contenido-abajo">
          <div class="contenedor ancho-lectura">
            <h1 class="titulo-eni texto-centrado texto-color-1">
              SISTEMA DE INFORMACION JEBOAX
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="contenedor ancho-lectura m-y-10">
      <h2 class="texto-centrado m-y-8-esc m-y-4-mov m-x-auto">Inicio</h2>
      <div class="contenedor ancho-lectura texto-centrado">
        <h3>Lista de plantas</h3>
        <ul>
          <li><a href="/amate">amate amarillo</a></li>
          <li><a href="/anil">añil</a></li>
          <li><a href="/barbasco">barbasco</a></li>
          <li><a href="/calabaza">calabaza</a></li>
          <li><a href="/cardon">cardón</a></li>
          <li><a href="/cacalosuchil">cacalosúchil</a></li>
          <li><a href="/copaoro">copa de oro</a></li>
          <li><a href="/guie">guie’ xhuuba</a></li>
          <li><a href="/huaje">huaje</a></li>
          <li><a href="/jicaro">jícaro</a></li>
          <li><a href="/linaloe">linaloe</a></li>
          <li><a href="/MaizCocostle">maíz de cocostle</a></li>
          <li><a href="/Mantecoso">piñon</a></li>
          <li><a href="/Nanachepa">cuatomate</a></li>
          <li><a href="/NopalSanGabriel">nopal San Gabriel</a></li>
          <li><a href="/PalmaReal">palma real</a></li>
          <li><a href="/PataDeGallo">pata de gallo</a></li>
          <li><a href="/RanitaSurena">ranita de los altos del sur</a></li>
          <li><a href="/RositaCacao">rosita de cacao</a></li>
          <li><a href="/sabino">sabino</a></li>
          <li><a href="/VenturillaPlanta">venturilla</a></li>
          <li><a href="/YerbaBorracho">yerba de borracho</a></li>
        </ul>
      </div>
    </div>
  </main>
</template>

<style lang="scss">
p,
u,
li {
  font-size: 1.1rem;
}
</style>
